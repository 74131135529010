import { Inject, PLATFORM_ID } from '@angular/core';
import { StorageService } from './storage.service';
import { isPlatformBrowser } from '@angular/common';

export class LocalStorageService implements StorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  setItem(key: string, value: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, value);
    }
  }
  removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.removeItem(key);
    }
  }
  getItem(key: string): string | null {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(key);
    }

    return null;
  }
}
