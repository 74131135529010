import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, BehaviorSubject } from 'rxjs';

import { BreadcrumbComponent } from '../../breadcrumb.component';
import { CommonModule, NgIf } from '@angular/common';
import { IBreadcrumb } from '@ea/models';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'ea-router-breadcrumbs',
  standalone: true,
  imports: [CommonModule, BreadcrumbComponent, NgIf],
  template: ` <ng-container *ngIf="breadcrumbs$ | async as items">
    <ea-breadcrumb [items]="items"></ea-breadcrumb>
  </ng-container>`,
  styles: [``],
})
export class RouterBreadcrumbsComponent
  extends BaseComponent
  implements OnDestroy
{
  breadcrumbs$: BehaviorSubject<IBreadcrumb[]> = new BehaviorSubject<
    IBreadcrumb[]
  >([]);

  constructor(private route: ActivatedRoute, private router: Router) {
    super();

    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.build(this.route.root))
        )
        .subscribe((breadcrumbs) => {
          breadcrumbs =  breadcrumbs.filter(x => !x.link.includes('account'));
          this.breadcrumbs$.next(breadcrumbs)
        })
    );
  }

  private build(
    route: ActivatedRoute,
    path: string = '',
    breadcrumbs: IBreadcrumb[] = []
  ): IBreadcrumb[] {
    const title = route.routeConfig?.data?.['title'];
    const current = route.routeConfig?.path;

    const nextPath = current ? `${path}/${current}` : path;

    const items: IBreadcrumb[] = title
      ? [...breadcrumbs, { name: title, link: nextPath }]
      : breadcrumbs;

    if (route.firstChild) {
      return this.build(route.firstChild, nextPath, items);
    }

    return items;
  }
}
