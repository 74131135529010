import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchState } from './search.interface';

export const FEATURE_NAME = 'search';
const getState = createFeatureSelector<SearchState>(FEATURE_NAME);

const getSearch = createSelector(getState, (state) => state.search);

const getPage = createSelector(getState, (state) => state.page);

const getSize = createSelector(getState, (state) => state.size);

export const searchSelectors = {
  getSearch,
  getPage,
  getSize,
};
