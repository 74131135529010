import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CategoryService } from 'src/app/services/shared/category/category.service';
import { Meta } from '@angular/platform-browser';

@NgModule({
  imports: [CommonModule],
  providers: [CategoryService, Meta],
})
export class CategoryModule {}
