import { StorageService } from './storage.service';

export class InMemoryLocalStorageService implements StorageService {
  private data = new Map<string, string>();

  setItem(key: string, value: string): void {
    this.data.set(key, value);
  }

  removeItem(key: string): void {
    this.data.delete(key);
  }

  getItem(key: string): string | null {
    return this.data.get(key) || null;
  }
}
