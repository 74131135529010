import { HttpClient } from '@angular/common/http';
import { Provider } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function i18nHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const i18nLoader: Provider = {
  provide: TranslateLoader,
  useFactory: i18nHttpLoaderFactory,
  deps: [HttpClient],
};

function i18nHttpLoaderFactoryCreator(path: string) {
  return function (http: HttpClient) {
    return new TranslateHttpLoader(http, `./assets/i18n/${path}/`, '.json');
  };
}

export const createI18nLoader = (path: string): Provider => {
  return {
    provide: TranslateLoader,
    useFactory: i18nHttpLoaderFactoryCreator(path),
    deps: [HttpClient],
  };
};
