import { createAction, props } from '@ngrx/store';

export enum SearchActionTypes {
  INIT = '[search] INIT',
  SET_SEARCH = '[search] SET_SEARCH',
}

const init = createAction(SearchActionTypes.INIT);

const setSearch = createAction(
  SearchActionTypes.SET_SEARCH,
  props<{ search: string; page?: number; size?: number }>()
);

export const searchActions = {
  init,
  setSearch,
};
