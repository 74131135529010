import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import environment from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private renderer2: Renderer2;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private meta: Meta,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }
  updateAlternateLink(href: string) {
    let hreflang = environment.base == 'uk' ? 'en-GB' : 'en-US';
    let link = this.renderer2.selectRootElement(
      `link[rel='alternate'][hreflang]`,
    );
    if (link) {
      link.setAttribute('hreflang', hreflang);
      link.setAttribute('href', href);
    } else {
      const newLink: HTMLLinkElement = this.renderer2.createElement('link');
      newLink.setAttribute('rel', 'alternate');
      newLink.setAttribute('hreflang', hreflang);
      newLink.setAttribute('href', href);
      this.renderer2.appendChild(this._document.head, newLink);
    }
  }

  removeAlternateLink() {
    let hreflang = environment.base == 'uk' ? 'en-GB' : 'en-US';
    const link: HTMLLinkElement | null = this.renderer2.selectRootElement(
      `link[rel='alternate'][hreflang='${hreflang}']`,
    );
    if (link) {
      this.renderer2.removeChild(this._document.head, link);
    }
  }
}
