import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { searchSelectors } from './search.selectors';
import { searchActions } from './search.actions';
import { SearchState } from './search.interface';

@Injectable()
export class SearchFacade {
  search$ = this.store.select(searchSelectors.getSearch);
  page$ = this.store.select(searchSelectors.getPage);
  size$ = this.store.select(searchSelectors.getSize);

  constructor(private store: Store<SearchState>) {}

  init() {
    this.store.dispatch(searchActions.init());
  }

  setSearch(
    search: string,
    { page, size }: { page?: number; size?: number } = {}
  ) {
    this.store.dispatch(searchActions.setSearch({ search, page, size }));
  }
}
