import {
  bootstrapApplication,
  provideClientHydration,
} from '@angular/platform-browser';
//MODULES
// PAGES
import { AppComponent } from './app/app.component';
//PROVIDERS
import { provideRouter } from '@angular/router';
import { enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
// STATE MANAGEMENT
import { providers } from './app/app.config';
import { provideServiceWorker } from '@angular/service-worker';
import environment from './environments/environment';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(providers),
    provideRouter([
      {
        path: 'our-story',
        title: 'Our Story | EnableAll',
        loadComponent: () =>
          import(
            /* webpackChunkName: "our-story-page" */ './app/pages/our-story-page/our-story-page.component'
          ).then((x) => x.OurStoryPageComponent),
      },
      {
        path: 'our-values',
        title: 'Our Values | EnableAll',
        loadComponent: () =>
          import(
            /* webpackChunkName: "our-story-page" */ './app/pages/our-values-page/our-values-page.component'
          ).then((x) => x.OurValuesPageComponent),
      },
      {
        path: 'accessibility-statement',
        title: 'Accessibility Statement | EnableAll',
        loadComponent: () =>
          import(
            /* webpackChunkName: "accessibility-statement-page" */ './app/pages/accessibility-statement-page/accessibility-statement-page.component'
          ).then((x) => x.AccessibilityStatementPageComponent),
      },
      {
        path: 'delivery-policy',
        title: 'Delivery Policy | EnableAll',
        loadComponent: () =>
          import(
            /* webpackChunkName: "delivery-policy-page" */ './app/pages/delivery-policy-page/delivery-policy-page.component'
          ).then((x) => x.DeliveryPolicyPageComponent),
      },
      {
        path: 'refunds-and-exchanges',
        title: 'Returns Policy | EnableAll',
        loadComponent: () =>
          import(
            /* webpackChunkName: "refunds-and-exchanges-page" */ './app/pages/refunds-and-exchanges-page/refunds-and-exchanges-page.component'
          ).then((x) => x.RefundsAndExchangesPageComponent),
      },
      {
        path: 'contact-us',
        title: 'Contact Us | EnableAll',
        loadComponent: () =>
          import(
            /* webpackChunkName: "contact-us-page" */ './app/pages/contact-us-page/contact-us-page.component'
          ).then((x) => x.ContactUsPageComponent),
      },
      {
        path: 'contact-us/retailer-enquiry',
        loadComponent: () =>
          import(
            /* webpackChunkName: "contact-us-vendor-page" */ './app/pages/contact-us-vendor-page/contact-us-vendor-page.component'
          ).then((x) => x.ContactUsVendorPageComponent),
      },
      {
        path: 'privacy-policy',
        title: 'Privacy Policy | EnableAll',
        loadComponent: () =>
          import(
            /* webpackChunkName: "privacy-policy-page" */ './app/pages/privacy-policy-page/privacy-policy-page.component'
          ).then((x) => x.PrivacyPolicyPageComponent),
      },
      {
        path: 'web-usage-policy',
        title: 'Website Usage | EnableAll',
        loadComponent: () =>
          import(
            /* webpackChunkName: "web-usage-policy-page" */ './app/pages/web-usage-policy-page/web-usage-policy-page.component'
          ).then((x) => x.WebUsagePolicyPageComponent),
      },
      {
        path: 'customer-terms',
        title: 'Customer Terms | EnableAll',

        loadComponent: () =>
          import(
            /* webpackChunkName: "customer-terms-page" */ './app/pages/customer-terms-page/customer-terms-page.component'
          ).then((x) => x.CustomerTermsPageComponent),
      },
      {
        path: 'supplier-terms',
        title: 'EnableAll Supplier Terms | EnableAll',
        loadComponent: () =>
          import(
            /* webpackChunkName: "enableAll-supplier-terms-page" */ './app/pages/enableAll-supplier-terms-page/enableAll-supplier-terms-page.component'
          ).then((x) => x.EnableAllSupplierTermsPageComponent),
      },
      {
        path: 'review-guidelines',
        title: 'Review guidelines | EnableAll',
        loadComponent: () =>
          import(
            /* webpackChunkName: "enableAll-review-guidelines-page" */ './app/pages/enableAll-review-guidelines-page/enableAll-review-guidelines-page.component'
          ).then((x) => x.EnableAllReviewGuideLinesPageComponent),
      },
      { path: '**', redirectTo: '/' },
    ]),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
      scope: environment.base,
    }),
    provideClientHydration(),
    provideAnimationsAsync(),
  ],
}).catch((err) => console.log(JSON.stringify(err)));
