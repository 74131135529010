//MODULES
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountModule } from './modules/account/account.module';
import { AuthModule } from './modules/auth/auth.module';
import { EffectsModule } from '@ngrx/effects';
import {
  HttpClientModule,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { StoreModule } from '@ngrx/store';
import { SearchModule } from './modules/search';
import { CartFacade, CartModule } from './modules/cart';

//PROVIDERS
import { provideRouter, RouterModule } from '@angular/router';
import {
  ApplicationConfig,
  importProvidersFrom,
  ImportProvidersSource,
} from '@angular/core';
import { AuthFacade, authReducer } from './modules/auth/+state';
import { CategoryModule } from './modules/category/category.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { i18nLoader } from './modules/i18n';

import { StorageModule } from './modules/storage/storage/storage.module';
import { AuthEffects } from './modules/auth/+state/auth.effects';
import { CartEffects } from './modules/cart/+state/cart.effects';
import { VendorModule } from './modules/vendors/vendor.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SplitPipe } from './modules/products/pipes/split.pipe';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AppRoutes } from './app.routes';
import { cookieConfig } from './app.cookies';
import {
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';

export const providers: ImportProvidersSource = [
  HttpClientModule,
  BrowserAnimationsModule,
  EffectsModule.forRoot([AuthEffects, AuthFacade, CartFacade, CartEffects]),
  RouterModule.forRoot(AppRoutes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking',
    bindToComponentInputs: true,
    enableTracing: false,
  }),
  NgcCookieConsentModule.forRoot(cookieConfig),
  StoreModule.forRoot({ auth: authReducer }),
  AuthModule,
  AccountModule,
  CategoryModule,
  SearchModule,
  CartModule,
  LoadingBarModule,
  LoadingBarRouterModule,
  TranslateModule.forRoot({
    defaultLanguage: 'en',
    loader: i18nLoader,
  }),
  FormsModule,
  StorageModule,
  VendorModule,
  NgxSkeletonLoaderModule,
  SplitPipe,
];

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(...providers),
    provideRouter(AppRoutes),
    provideClientHydration(
      withHttpTransferCacheOptions({ includePostRequests: true })
    ),
    provideHttpClient(withFetch()),
  ],
};
