import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain:
      typeof window !== 'undefined'
        ? window.location.hostname
        : 'enableall.com',
  },
  palette: {
    popup: {
      background: '#000',
      text: 'white',
    },
    button: {
      background: '#b3002f',
    },
  },
  position: 'top',
  theme: 'block',
  type: 'opt-in',
};
