import { NgModule, PLATFORM_ID } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { STORAGE_SERVICE } from './providers';
import { storageProviderFactory } from './factories';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: STORAGE_SERVICE,
      useFactory: storageProviderFactory,
      deps: [PLATFORM_ID, DOCUMENT],
    },
  ],
})
export class StorageModule {}
