<div #top></div>

<ngx-loading-bar
  color="var(--primary-color)"
  [includeSpinner]="false"
></ngx-loading-bar>

<ea-toaster></ea-toaster>

<a
  href="javascript:goTo({{ content }})"
  *ngIf="isBrowser"
  (click)="skipTo(content)"
  class="skip-link skip-to-navigation cdk-visually-hidden"
  title="{{ 'links.skip_to_content' | translate }}"
>
  {{ "links.skip_to_content" | translate }}
</a>
<a
  *ngIf="isBrowser"
  href="javascript:goTo({{ navigation }})"
  (click)="skipTo(navigation)"
  class="skip-link skip-to-navigation cdk-visually-hidden"
  title="{{ 'links.skip_to_nav' | translate }}"
>
  {{ "links.skip_to_nav" | translate }}
</a>

<!-- Header -->
<div
  #top
  id="top"
  class="bg-theme-surface border-theme-secondary sticky-header"
  [ngClass]="{'sticky-header' : isSticky}"
  *ngIf="categories | async as cats"
>
  <ea-masthead [categories]="cats"></ea-masthead>

  <div class="hidden lg:block" #navigation>
    <ea-nav [categories]="cats"></ea-nav>
  </div>
  <div class="d-block d-lg-none my-2">
    <ea-search-bar [isSmallScreen]="true"></ea-search-bar>
  </div>
</div>

<!-- Content -->
<main id="content" #content class="d-flex flex-column min-vh-100">
  <div class="breadcrumbs">
    <ea-router-breadcrumbs></ea-router-breadcrumbs>
  </div>
  <router-outlet></router-outlet>
</main>

<button
  href="javascript:void(0)"
  (click)="skipTo(top)"
  class="p-3 text-center bg-theme-surface font-bold w-100 focus-outline"
  title="back-to-top"
>
  Back to top
</button>

<!-- Footer -->
<div id="bottom" class="bg-theme-surface" #bottom>
  <ea-footer></ea-footer>
</div>
