import { InMemoryLocalStorageService } from './services/in-memory-storage.service';
import { LocalStorageService } from './services/local-storage.service';

export function storageProviderFactory(platformId: Object, document: Document) {
  if (typeof document?.defaultView?.['localStorage'] !== 'undefined') {
    return new LocalStorageService(platformId);
  }

  return new InMemoryLocalStorageService();
}
