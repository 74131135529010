import { Provider, InjectionToken } from '@angular/core';
import { StorageService } from './services/storage.service';
import { LocalStorageService } from '@ea/services';
import { InMemoryLocalStorageService } from './services';

export const STORAGE_SERVICE = new InjectionToken<StorageService>(
  'storageService'
);

export const providers: Provider[] = [
  { provide: STORAGE_SERVICE, useClass: LocalStorageService },
  { provide: STORAGE_SERVICE, useClass: InMemoryLocalStorageService },
];
