import { Action, createReducer, on } from '@ngrx/store';
import { searchActions } from './search.actions';
import { INITIAL_STATE, SearchState } from './search.interface';

const reducer = createReducer(
  INITIAL_STATE,

  on(searchActions.init, () => INITIAL_STATE),

  on(searchActions.setSearch, (state, { search, page, size }) => ({
    ...state,
    search,
    page: page || state.page,
    size: size || state.size,
  }))
);

export function searchReducer(state: SearchState | undefined, action: Action) {
  return reducer(state, action);
}
