import { NgModule } from '@angular/core';

import { SearchFacade } from './+state/search.facade';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME, searchReducer } from './+state';

@NgModule({
  declarations: [],
  providers: [SearchFacade],
  imports: [StoreModule.forFeature(FEATURE_NAME, searchReducer)],
})
export class SearchModule {}
