<div class="container-fluid ea-about-us">
    <div class="row seller-breadcrumbs">
        <!-- <div class="col-sm-12 col-md-12 col-lg-6 aboutus-breadcrumbs-col">            
            <div class="aboutus-breadcrumbs-text">Home > About us</div>
        </div> -->
    </div>
    <div class="row main-content">
        <div class="title-content col-lg-7 col-12">
            <div class="title-header">
                Who we are  
            </div>
            <div class="mt-4" style="font-size: 16px;">
                <p>Welcome to EnableAll – the UK’s first fully accessible e-commerce marketplace, where everyone is invited to shop with ease, no matter their needs.</p>
                <br> 
                <p>We believe that online shopping should be for everyone, so our platform is thoughtfully designed to adapt to you. Whether you’re living with visual impairments, colour blindness, cognitive or motor difficulties, hearing loss, or neurodiversity, EnableAll is here to make sure your shopping experience is seamless, reliable, and truly inclusive.</p>
                <br>  
                <p>Join us in celebrating a marketplace where accessibility is at the heart of everything we do!</p>
            </div>
        </div>
        <div class="media-content col-lg-5 col-12 m-1">
            <div class="title-media-block">
                <video class="title-media" alt="branding video" src="https://enablealldata.blob.core.windows.net/public/EnableAll_About_Us_Brand.mp4" poster="assets/enableAllVideoPoster.png" controls></video>
            </div>
        </div>
    </div>
    
    <!-- <div class="row brand-promise">
        <div class="brand-promise-header">Our brand promise</div>
        <div class="brand-promise-div d-flex">
            <div class="brand-promise-block">
                <div class="brand-promise-circle-block">
                    <div class="brand-promise-circle justify-content-center d-flex" style="  background-color: #F21C5C;">
                        <img
                        ngSrc="assets/images/about-us/ass.svg"
                        alt="      Truly accessible online marketplace"
                        width="60"
                        height="60"
                        data-sizes="auto"
                        class="lazyload"
                      />
                    </div>
                    <div class="brand-promise-text " style="margin-left: -10px;">
                        Truly accessible online marketplace
                    </div>  
                </div>
          
                     
            </div>
            <div class="brand-promise-block">
                <div class="brand-promise-circle-block">
                    <div class="brand-promise-circle justify-content-center d-flex" style="  background-color: #F21C5C;">
                        <img
                        ngSrc="assets/images/about-us/invat.svg"
                        alt="Clothing and Shoes"
                        width="60"
                        height="60"
                        data-sizes="auto"
                        class="lazyload"
                      />
                    </div>
                </div>
                <div class="brand-promise-text">
                    Innovative and pioneering
                </div>           
            </div>
            <div class="brand-promise-block">
                <div class="brand-promise-circle-block">
                    <div class="brand-promise-circle justify-content-center d-flex" style="  background-color: #F21C5C;">
                        <img
                        ngSrc="assets/images/about-us/etic.svg"
                        alt="Ethical alternative for socially consious consumers"
                        width="60"
                        height="60"
                        data-sizes="auto"
                        class="lazyload"
                      />
                    </div>
                </div>
                <div class="brand-promise-text">
                    Ethical alternative for socially consious consumers
                </div>           
            </div>
            <div class="brand-promise-block" style="align-items: center; text-align: center;">
                <div class="brand-promise-circle-block">
                    <div class="brand-promise-circle justify-content-center d-flex" style="  background-color: #F21C5C;">
                        <img
                        ngSrc="assets/images/about-us/emc.svg"
                        alt="Economic opportunities for retailers"
                        width="60"
                        height="60"
                        data-sizes="auto"
                        class="lazyload"
                      />
                    </div>
                </div>
                <div class="brand-promise-text ustify-content-center d-flex" style="text-align: center; align-items: center;">
                    Economic opportunities for retailers
                </div>           
            </div>
        </div>
    </div> -->
    <br>
    <div class="row why-we-exist mt-4">
      <div class="col-12 col-md-8 col-lg-8">
       
          <div class="why-we-exist-p" style="font-size: 16px;">
            Has online shopping ever left you thinking, surely they could #DoBetter? We invite you to share your experiences through our short survey. Our goal is to amplify the voices of millions of disabled shoppers and their families, who are often underrepresented. Let’s #DoBetter together.          </div>
      </div>
      <div class="col-12 col-md-4  col-lg-4 mt-4">
        <div class="title-get-started d-flex justify-content-center">
          <a
          href="javascript:void(0)"
          class="ea-button ea-button-secondary focus-ring justify-content-around"
          onclick="document.getElementById('targetForm').scrollIntoView({ behavior: 'smooth', block: 'start' })"
        >
        #DoBetter survey
        </a>
        </div>
      </div>
  </div>
    <section class="icon-section mt-4">
        <div class="row brand-promise" >
            <div class="brand-promise-header title-header" style="font-size: 32px; font-weight: 900;">Our brand promise</div>
          </div>
        <div class="about-us-icons icons">
      
          <div class="row">
            <!--a11y-->
            <div class="col-sm-6 col-md-4 col-lg-2" >
              <div class="icon  border-radius-100"   >
                <img
                  ngSrc="assets/images/about-us/ass.svg"
                  alt="Truly accessible online marketplace"
                  width="60"
                  height="60"
                  data-sizes="auto"
                  class="lazyload"
                />
              </div>
              <p class="text-theme-on-background font-bold">
                Truly accessible online marketplace
            </div>
      
            <!--marketplace-->
            <div class="col-sm-6 col-md-4 col-lg-2"  >
              <div class="icon marketplace purple border-radius-100">
                <img
                ngSrc="assets/images/about-us/invat.svg"
                  alt="Innovative and pioneering"
                  data-sizes="auto"
                  width="60"
                  height="60"
                  class="lazyload"
                />
              </div>
              <p class="text-theme-on-background font-bold">
                Innovative and <br> pioneering 
            </div>
      
            <!--trajectory-->
            <div class="col-sm-6 col-md-4 col-lg-2" >
              <div class="icon trajectory green border-radius-100">
                <img
                ngSrc="assets/images/about-us/etic.svg"
                  width="60"
                  height="60"
                  alt="Home and Garden"
                  data-sizes="auto"
                  class="lazyload"
                />
              </div>
              <p class="text-theme-on-background font-bold">
                Ethical alternative for socially consious consumers
            </div>
      
            <!--paint-->
            <div class="col-sm-6 col-md-4 col-lg-2" >
              <div class="icon paint red border-radius-100">
                <img
                ngSrc="assets/images/about-us/emc.svg"
                  alt="Toys"
                  data-sizes="auto"
                  width="60"
                  height="60"
                  class="lazyload"
                />
              </div>
              <p class="text-theme-on-background font-bold">
                Economic <br>  opportunities for  <br>retailers
            </div>
          </div>
        </div>
      </section>
      
    
    
    <div class="row why-we-exist">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="why-we-exist-title">
                Why we exist
            </div>
            <div class="why-we-exist-p" style="font-size: 16px;">
                There are 1 billion disabled people around the world, all deserving the same digital opportunities as everyone else. In the UK alone, nearly a quarter of the working population relies on assistive design and technology—but did you know that 90% of websites still aren’t fully accessible? We saw how many disabilities, both visible and hidden, were being overlooked in the world of e-commerce, and we knew something had to change. At EnableAll, we believe accessibility should be the norm - for everyone. And we’re just getting started!
            </div>
        </div>
    </div>
    <!-- <div class="row our-values">
        <div class="col-sm-12 col-md-6 col-lg-6 our-values-block-col">
            <div class="our-values-div our-values-block">
                <div class="our-values-circle-block">
                    <div class="our-values-circle"></div>
                </div>
                <div class="our-values-text-block">
                    <div class="our-values-text-header">Our values</div>
                    <div class="our-values-text-content">
                        conterjhkla hldsj flkdsjhf kldsjh flkzdsjhf skaljfh lsdakj fsldakj sdalkjhf sdaklklsdj hasjdklsd jhklsadsd lhlk
                    </div>
                    <div class="read-more-block">
                        <div class="our-values-read-more">
                            Read More 
                        </div>
                        <span class="read-more-arrow_down">
                            <img alt="reach_millions" src="assets/icons/arrow-down.svg" />
                         </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6 sell-on-eall-col">
            <div class="our-values-div">
                <div class="our-values-circle-block">
                    <div class="our-values-circle"></div>
                </div>
                <div class="our-values-text-block">
                    <div class="our-values-text-header">Sell on EnableAll</div>
                    <div class="our-values-text-content">
                        conterjhkla hldsj flkdsjhf kldsjh flkzdsjhf skaljfh lsdakj fsldakj sdalkjhf sdaklklsdj hasjdklsd jhklsadsd lhlk
                    </div>
                    <div class="read-more-block">
                        <div class="our-values-read-more">
                            Read More 
                        </div>
                         <span class="read-more-arrow_down">
                            <img alt="reach_millions" src="assets/icons/arrow-down.svg" />
                         </span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

 <div class="row d-flex justify-content-center">
    <div class="col-sm-12 col-md-6 col-lg-6 services">
        <div class=" d-md-flex justify-content-md-around">
      
            <div class="row m-1">
              <!--a11y-->
    
                <div class="icon  border-radius-100 col-2"   >
                  <img
                    ngSrc="assets/images/about-us/ourValues.svg"
                    alt="Truly accessible online marketplace"
                    width="60"
                    height="60"
                    data-sizes="auto"
                    class="lazyload"
                  />
                </div>

              </div>
              <br>
          <div class=" ml-4">
            <h1 style="font-size: 32px; font-weight: 900;">Our values</h1> <br>
            <p style=" font-size: 16px;">At EnableAll, our values of inclusivity, innovation, and empathy drive us to create an accessible marketplace that empowers everyone to shop confidently, while breaking down barriers and always doing what’s right for disabled consumers and their supporters.</p>
            <br>
            <a href=" https://enableall.com/uk/our-values" class="mt-5" style="font-size: 17px; font-weight: 500;">Read more <mat-icon>arrow_drop_down</mat-icon></a>

          </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 services">
        <div class="  d-md-flex justify-content-md-around">
      
            <div class="row m-1">
              <!--a11y-->
    
                <div class="icon  border-radius-100 col-2"   >
                  <img
                    ngSrc="assets/images/about-us/market.svg"
                    alt="Truly accessible online marketplace"
                    width="60"
                    height="60"
                    data-sizes="auto"
                    class="lazyload"
                  />
                </div>

              </div>
              <br>

          <div class=" ml-4">
            <h1 style="font-size: 32px; font-weight: 900;">Sell on EnableAll</h1> <br>
            <p style=" font-size: 16px;">Selling on EnableAll means reaching a wider audience, including the 14.1 million disabled people and their families in the UK who deserve equal access to online shopping. Our marketplace is designed to ensure that everyone - no matter their abilities - can browse, shop, and purchase with ease.</p>
            <br>
            <a href="https://enableall.com/uk/seller" class="mt-5" style="font-size: 17px; font-weight: 500;">Read more <mat-icon>arrow_drop_down</mat-icon>
            </a>
          </div>
        </div>
    </div>
 </div>
</div>
<div class="your-story-form" id="targetForm" >
  <div class="form">
      <span class="header-title">Share your story​</span>
      <br>
      <p class="mt-5">Please complete the short survey below and help us lead the way in accessible online shopping.​</p>
      <p class="mt-5">
        Do you have lived experience of being disabled OR are you a family member or carer for someone with lived disability experience?​
      </p>
      <div class="answer-dev">
        <div class="mt-2 row">
          <div class="col-12 col-lg-3 col-sm-12 d-flex align-items-center"  tabindex="0">
            <input 
              class="form-radio-input mr-2" 
              type="radio" 
              name="hasLivedExperience" 
              [(ngModel)]="formData.hasLivedExperience" 
              value="yes" 
              id="yes" 
              style="width: 20px; height: 20px;"
              tabindex="0">
            <label for="yes" class="mb-0">Yes</label>
          </div>
      
          <div class="col-12 col-lg-3 col-sm-12 d-flex align-items-center"   tabindex="0">
            <input 
              class="form-radio-input mr-2" 
              type="radio" 
              name="hasLivedExperience" 
              [(ngModel)]="formData.hasLivedExperience"  
              value="no" 
              id="no" 
              style="width: 20px; height: 20px;"
              tabindex="0">
            <label for="no" class="mb-0">No</label>
          </div>
        </div>
      </div>
      
      
      
      <p class="mt-5">
        How often do you leave or abandon websites due to accessibility issues?​
      </p>
      <div class="answer-dev">
        <div class="mt-2 row">
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center " tabindex="0">
            <input class="form-radio-input mr-2" type="radio"name="frequencyOfLeaving" [(ngModel)]="formData.frequencyOfLeaving" value="daily" id="Daily" style="width: 20px; height: 20px;">
            <label for="Daily" class="mb-0">Daily</label>
          </div>
      
          <div class="col-12  col-md-3 col-sm-12 d-flex align-items-center " tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="frequencyOfLeaving" [(ngModel)]="formData.frequencyOfLeaving" value="Weekly" id="Weekly" style="width: 20px; height: 20px;">
            <label for="Weekly" class="mb-0">Weekly</label>
          </div>
      
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input  class="form-radio-input mr-2" type="radio" name="frequencyOfLeaving" [(ngModel)]="formData.frequencyOfLeaving" value="rarely" id="Rarely1" style="width: 20px; height: 20px;">
            <label for="Rarely1" class="mb-0">Rarely</label>
          </div>
      
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input tabindex="-1" class="form-radio-input mr-2" type="radio" name="frequencyOfLeaving" [(ngModel)]="formData.frequencyOfLeaving" value="Never" id="Never1" style="width: 20px; height: 20px;">
            <label for="Never1" class="mb-0">Never</label>
          </div>
        </div>
      </div>
      
      <p class="mt-5">What are the main reasons you leave a website without completing a purchase? <br>
        (Please select all that apply)</p>
      
      <div class="answer-dev p-4">
        <div class="form-check mt-3 d-flex align-items-center">
          <input type="checkbox" class="form-check-input" id="billingAddress1" [(ngModel)]="formData.abandonmentReasons.billingAddress">
          <label class="form-check-label ml-2 mt-1" for="billingAddress1">
           Confusing website layout​
          </label>
        </div>
      
        <div class="form-check mt-3 d-flex align-items-center">
          <input type="checkbox" class="form-check-input" id="billingAddress2" [(ngModel)]="formData.abandonmentReasons.screenReader">
          <label class="form-check-label ml-2 mt-1" for="billingAddress2">
            Unable to use a screen reader
          </label>
        </div>
      
        <div class="form-check mt-3 d-flex align-items-center">
          <input type="checkbox" class="form-check-input" id="billingAddress3" [(ngModel)]="formData.abandonmentReasons.keyboardNavigation">
          <label class="form-check-label ml-2 mt-1" for="billingAddress3">
            Difficult to navigate using only a keyboard
          </label>
        </div>
      
        <div class="form-check mt-3 d-flex align-items-center">
          <input type="checkbox" class="form-check-input" id="billingAddress4" [(ngModel)]="formData.abandonmentReasons.poorContrast">
          <label class="form-check-label ml-2 mt-1" for="billingAddress4">
            Poor contrast or unreadable text
          </label>
        </div>
      
        <div class="form-check mt-3 d-flex align-items-center">
          <input type="checkbox" class="form-check-input" id="billingAddress5" [(ngModel)]="formData.abandonmentReasons.complexCheckout">
          <label class="form-check-label ml-2 mt-1" for="billingAddress5">
            Complex checkout process
          </label>
        </div>
      
        <div class="form-check mt-3 d-flex align-items-center">
          <input type="checkbox" class="form-check-input" id="billingAddress6" [(ngModel)]="formData.abandonmentReasons.overwhelmingImagery">
          <label class="form-check-label ml-2 mt-1" for="billingAddress6">
            Overwhelm linked to imagery and/or videos
          </label>
        </div>
      
        <div class="form-check mt-3 d-flex align-items-center">
          <input type="checkbox" class="form-check-input" id="billingAddress7" [(ngModel)]="formData.abandonmentReasons.overwhelmingPopups">
          <label class="form-check-label ml-2 mt-1" for="billingAddress7">
            Overwhelm linked to ‘pop-up’ messages
          </label>
        </div>
      
        <div class="form-check mt-3 d-flex align-items-center">
          <input type="checkbox" class="form-check-input" id="billingAddress8" [(ngModel)]="formData.abandonmentReasons.confusingInstructions">
          <label class="form-check-label ml-2 mt-1" for="billingAddress8">
            Confusing instructions or product information
          </label>
        </div>
      
        <div class="form-check mt-3 d-flex align-items-center">
          <input type="checkbox" class="form-check-input" id="billingAddress9" [(ngModel)]="formData.abandonmentReasons.isOtherResons">
          <label class="form-check-label ml-2 mt-1" for="billingAddress9">
            Other reasons
          </label>
        </div>
      
        <input *ngIf="formData.abandonmentReasons.isOtherResons" type="text" class="input-text mt-3" placeholder="Please type any other reasons here"  [disabled]="!formData.abandonmentReasons.isOtherResons" [(ngModel)]="formData.abandonmentReasons.otherReasons">
      </div>
      
      
      <p class="mt-5">How often do accessibility issues make you switch to another website that is more accessible?</p>
      <div class="answer-dev">
        <div class="mt-2 row">
          <div class="col-12 col-md-2 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="switchFrequency" id="Always" value="Always" [(ngModel)]="formData.switchToOtherWebsiteFrequency" style="width: 20px; height: 20px;">
            <label for="Always" class="mb-0">Always</label>
          </div>
      
          <div class="col-12 col-md-2 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="switchFrequency" id="Often" value="Often" [(ngModel)]="formData.switchToOtherWebsiteFrequency" style="width: 20px; height: 20px;">
            <label for="Often" class="mb-0">Often</label>
          </div>
      
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="switchFrequency" id="Sometimes" value="Sometimes" [(ngModel)]="formData.switchToOtherWebsiteFrequency" style="width: 20px; height: 20px;">
            <label for="Sometimes" class="mb-0">Sometimes</label>
          </div>
      
          <div class="col-12 col-md-2 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="switchFrequency" id="Rarely" value="Rarely" [(ngModel)]="formData.switchToOtherWebsiteFrequency" style="width: 20px; height: 20px;">
            <label for="Rarely" class="mb-0">Rarely</label>
          </div>
      
          <div class="col-12 col-md-2 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="switchFrequency" id="Never" value="Never" [(ngModel)]="formData.switchToOtherWebsiteFrequency" style="width: 20px; height: 20px;">
            <label for="Never" class="mb-0">Never</label>
          </div>
        </div>
      </div>
      
      <p class="mt-5">How many purchases have you abandoned in the last 12 months because of website accessibility issues?</p>
      <div class="answer-dev">
        <div class="mt-2 row">
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="abandonedPurchases" id="None" value="None" [(ngModel)]="formData.purchaseAbandonmentFrequency" style="width: 20px; height: 20px;">
            <label for="None" class="mb-0">None</label>
          </div>
      
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="abandonedPurchases" id="1-5" value="1-5" [(ngModel)]="formData.purchaseAbandonmentFrequency" style="width: 20px; height: 20px;">
            <label for="1-5" class="mb-0">1-5</label>
          </div>
      
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="abandonedPurchases" id="5-10" value="5-10" [(ngModel)]="formData.purchaseAbandonmentFrequency" style="width: 20px; height: 20px;">
            <label for="5-10" class="mb-0">5-10</label>
          </div>
      
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="abandonedPurchases" id="MoreThan10" value="MoreThan10" [(ngModel)]="formData.purchaseAbandonmentFrequency" style="width: 20px; height: 20px;">
            <label for="MoreThan10" class="mb-0">More than 10</label>
          </div>
        </div>
      </div>
      

      <p class="mt-5">How much time do you typically spend trying to overcome an accessibility barrier before leaving a website?</p>
      <div class="answer-dev">
        <div class="mt-2 row">
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="timeSpent" id="LessThan1Minute" value="LessThan1Minute" [(ngModel)]="formData.timeSpentOvercomingBarrier" style="width: 20px; height: 20px;">
            <label for="LessThan1Minute" class="mb-0">Less than 1 minute</label>
          </div>
      
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="timeSpent" id="1To3Minutes" value="1To3Minutes" [(ngModel)]="formData.timeSpentOvercomingBarrier" style="width: 20px; height: 20px;">
            <label for="1To3Minutes" class="mb-0">1-3 minutes</label>
          </div>
      
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="timeSpent" id="3To5Minutes" value="3To5Minutes" [(ngModel)]="formData.timeSpentOvercomingBarrier" style="width: 20px; height: 20px;">
            <label for="3To5Minutes" class="mb-0">3-5 minutes</label>
          </div>
      
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="timeSpent" id="MoreThan5Minutes" value="MoreThan5Minutes" [(ngModel)]="formData.timeSpentOvercomingBarrier" style="width: 20px; height: 20px;">
            <label for="MoreThan5Minutes" class="mb-0">More than 5 minutes</label>
          </div>
        </div>
      </div>
      
      <p class="mt-5">If a website with accessibility barriers improved, how likely are you to return and make a purchase?​​</p>

      <div class="answer-dev">
        <div class="mt-2 row">
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="options" id="VeryLikely" style="width: 20px; height: 20px;" [(ngModel)]="formData.likelihoodToReturnIfImproved" value="Very likely">
            <label for="VeryLikely" class="mb-0">Very likely</label>
          </div>
      
          <div class="col-12 col-md-3 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="options" id="SomewhatLikely" style="width: 20px; height: 20px;" [(ngModel)]="formData.likelihoodToReturnIfImproved" value="Somewhat likely">
            <label for="SomewhatLikely" class="mb-0">Somewhat likely</label>
          </div>
      
          <div class="col-12 col-md-2 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="options" id="Neutral" style="width: 20px; height: 20px;" [(ngModel)]="formData.likelihoodToReturnIfImproved" value="Neutral">
            <label for="Neutral" class="mb-0">Neutral</label>
          </div>
      
          <div class="col-12 col-md-2 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="options" id="Unlikely" style="width: 20px; height: 20px;" [(ngModel)]="formData.likelihoodToReturnIfImproved" value="Unlikely">
            <label for="Unlikely" class="mb-0">Unlikely</label>
          </div>
      
          <div class="col-12 col-md-2 col-sm-12 d-flex align-items-center" tabindex="0">
            <input class="form-radio-input mr-2" type="radio" name="options" id="VeryUnlikely" style="width: 20px; height: 20px;" [(ngModel)]="formData.likelihoodToReturnIfImproved" value="Very unlikely">
            <label for="VeryUnlikely" class="mb-0">Very unlikely</label>
          </div>
        </div>
      </div>
      

        <label class="mt-5" for="story" style=" font-size: 18px !important ;font-weight: 700 !important;">If you’ve had a particularly good or poor online shopping experience, please share your story with us here. (No brands will be publicly shared.)​ </label>
      <input class="input-text" type="text" placeholder="Tell us your story" id="story" [(ngModel)]="formData.story">
      
     <label class="mt-5" for="email-input" style=" font-size: 18px !important ;font-weight: 700 !important;" class="mt-5">Email <span class="required"> (Required)</span></label>
      <input class="input-text" type="text" placeholder="Enter your email address" id="email-input"  [(ngModel)]="formData.email">
      

      <div class="mt-4">
        <!-- First Checkbox: Consent for feedback -->
        <div class="mt-4 w-100 d-flex align-items-center">
          <input type="checkbox" class="form-check-input mr-2" id="useFeedbackConsent" [(ngModel)]="feedcheckbox.useFeedbackConsent" style="width: 25px; height: 25px;" />
          <label class="form-check-label mt-1" for="useFeedbackConsent">
            I agree to receive communications from EnableAll.
          </label>
        </div>
      
        <!-- Second Checkbox: Consent to receive communications -->
        <div class="mt-4 w-100 d-flex align-items-center">
          <input type="checkbox" class="form-check-input mr-2" id="useCommunicationConsent" [(ngModel)]="feedcheckbox.useCommunicationConsent" style="width: 25px; height: 25px;" />
          <label class="form-check-label mt-1" for="useCommunicationConsent">
            I consent to the storage of my data according to EnableAll's privacy policy. ​
          </label>
        </div>
      </div>
      
      <button class="ea-button ea-button-secondary mt-5" (click)="submitForm()" [disabled]="isLaod || !feedcheckbox.useCommunicationConsent ||  !formData.email"> submit</button>
  </div>
</div>