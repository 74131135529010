export interface SearchState {
  page: number;
  size: number;
  search: string;
}

export const INITIAL_STATE: SearchState = {
  search: '',
  page: 0,
  size: 12,
};
